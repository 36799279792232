import { Agency } from './agency'
import { Priority, User } from './user'
import { Primitive } from './various'
import { Company } from '../api/companies/types'
import { Money } from '../api/campaign/types'

export interface Campaign {
  alerts: Alert[]
  acceptanceAlerts: Alert[]
  aggregatedAcceptanceAlerts: Alert[]
  approvals: Approval[]
  audience: number
  hiddenAudience: number
  hiddenAudienceActive: boolean
  brainState: Record<string, unknown>
  briefName: string
  budget: number
  budgetCurrency: CampaignBudgetCurrency
  acceptanceStatus: CampaignAcceptanceStatus
  creations: Creation[]
  agency: Agency
  advertiser: Company
  impressions: number
  emissionType: EmissionType
  hiddenImpressions: number
  hiddenImpressionsActive: boolean
  endDate: string
  everUsedMedia: number[]
  id: string
  mediaCriteriaSearch: MediaCriteriaSearch
  mediumFormat: MediaFormat
  name: string
  note: string
  contractNote: string
  offer: OFFER | ADDITIONAL_OFFER | null
  pdfUrl: string
  priority: Priority
  priorityRanges: number[]
  progress: CampaignProgress
  reservationTill: Date | null
  startDate: string
  status: CampaignStatus
  supervisors: User[]
  target: CampaignTarget
  uuid: string
  commercialAttribute: CommercialAttribute | null
  organizationAttribute: OrganizationAttributes | null
  createdAt: string
  currentValue: Money | null
  estimatedValue: Money | null
  mediaDuration: number
}

export interface OrganizationAttributes {
  commercial: boolean
  complaint: string
  freeComplaint: boolean
  outsidePh: boolean
  social: boolean
  productOffer: boolean
}

export interface Approval {
  approved: boolean
  id: string
  category: ApprovalCategory
}

export interface Alert {
  type: AlertType
  active: boolean
  event?: AlertEvent
  genre?: AlertGenre
  message: string
}

export type CommercialAttribute = 'best_effort' | 'automatic_guarantee'

export const defaultCommercialAttribute: CommercialAttribute = 'automatic_guarantee'

export enum AlertEvent {
  OVERBOOKING = 'overbooking',
}

export enum AlertGenre {
  CAMPAIGN = 'campaign',
}

export enum ApprovalCategory {
  CAMPAIGN_ACCEPTED = 'campaign_accepted',
  AGREEMENT_SIGNED = 'agreement_signed',
}

export enum CampaignBudgetCurrency {
  PLN = 'pln',
}

export enum AlertType {
  warning = 'warning',
  alert = 'alert',
  info = 'info',
}

export interface PdfCreation extends Creation {
  rowNumber: number
}

export interface Creation {
  archiveReason: string
  details: string
  approved: boolean
  containsAlcohol: boolean
  archivedAt?: string
  createdAt: string
  file: CreationFile
  id: string
  mediaFormat: MediaFormat
  thumbnailUrl: string
  uuid: string
  state: CreationStatus
  emissionStartDate: string
  duration: number
  alerts: CreationAlert[]
  schedules: CreationSchedule[]
  impressions: number
  audience: number
}

export type CreationFormat = 'no_video' | 'video'

export interface CreationFile {
  bitrate: number
  duration: number
  filename: string
  frameRate: string
  height: number
  isVideo: boolean
  mimeType: string
  size: number
  url: string
  width: number
}

export enum CreationStatus {
  CREATED = 'created',
  SENT = 'sent',
  EDITED = 'edited',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export interface CreationAlert {
  active: boolean
  event: string
}

export type CreationMedium = Pick<Medium, 'address' | 'asId' | 'city' | 'id'>

export enum MediaFormat {
  DG_CL = 'dgCl',
  DG_IN = 'dgIn',
  DG_IN_H = 'dgInH',
  DG_IN_S = 'dgInS',
  DG_CS = 'dgCs',
}

export const MediaFormatIndoors = [
  MediaFormat['DG_IN'],
  MediaFormat['DG_IN_H'],
  MediaFormat['DG_IN_S'],
]

export type Medium = {
  address: string
  agglomeration: MediumPlaceDetails | null
  asId: number
  building: MediumPlaceDetails | null
  city: MediumPlaceDetails
  comment: string
  id: string
  images: MediumDisplayPhoto[]
  lat: number
  lon: number
  mediaFormat: MediaFormat
  placement: MediumPlacement
  screenCount: number
  status: MediumStatus
  used: boolean
  vac: number
  poiCategories: string[]
  creationFormat: CreationFormat
}

export interface MediumDisplayPhoto {
  id: string
  imageUrl: string
  thumbnailUrl: string
}

export interface MediumPlaceDetails {
  id: string
  name: string
}

export enum MediumPlacement {
  INDOOR = 'indoor',
  OUTDOOR = 'outdoor',
}

export enum MediumStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  NOT_OPERATING = 'not_operating',
  REMOVED = 'removed',
}

export interface MediaCriteriaSearch {
  agglomerations: string[]
  buildings: string[]
  cities: string[]
  mediumFormat: MediaFormat
  mediaAsIds: string[]
  pois: string[]
  sex: number[]
  ageGroups: number[]
}

export interface CampaignProgress {
  percent: number
  status: CampaignProgressStatus
}

export enum CampaignProgressStatus {
  OK = 'ok',
  PROBLEM = 'problem',
}

export enum CampaignStatus {
  SKETCH = 'sketch',
  PLANNED = 'planned',
  ACTIVE = 'active',
  FINISHED = 'finished',
  PAUSED = 'paused',
  STOPPED = 'stopped',
  RESERVED = 'reserved',
  RESERVATION_CANCELLED = 'reservation_cancelled',
  PLANNED_CANCELLED = 'planned_cancelled',
}

export enum CampaignAcceptanceStatus {
  accepted = 'sketch',
  not_sent = 'not_sent',
  pending_acceptation = 'pending_acceptation',
  rejected = 'rejected',
}

export enum CampaignSortColumn {
  NAME = 'name',
  AGENCY_NAME = 'agency_name',
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  STATUS = 'status',
  ALERTS = 'alerts',
  COMMERCIAL_ATTRIBUTES = 'commercial_attributes',
}

export interface CampaignDetails {
  currentBudget: number
  currentEmissions: number
  currentAudience: number
  emissionType: EmissionType
  durationDays: number
  everUsedMedia: Medium[]
  remainingDays: number
  target: CampaignTarget
  targetCompletion: number
  totalBudget: number
  totalAudience: number
  totalEmissions: number
}

export enum CampaignTarget {
  BUDGET = 'budget',
  IMPRESSIONS = 'impressions',
  AUDIENCE = 'audience',
}

export interface CreationSchedule {
  startDate: Date
  endDate: Date
}

export interface CampaignChartFilters {
  agglomerations: string[]
  buildings: string[]
  cities: Medium['city']['name'][]
  mediaAsIds: string[]
  pois: string[]
  sex: number[]
  ageGroups: number[]
}

export type MediumUsage = {
  id: Medium['id']
  used: boolean
}

export enum OFFER {
  RUN_ON_NETWORK = 'run_on_network',
  AGGLOMERATIONS = 'agglomerations',
  BUILDINGS = 'buildings',
  MEDIA_SELECTION = 'media_selection',
}

export enum ADDITIONAL_OFFER {
  NETWORK_G14_PLUS = 'network_g14_plus',
  NETWORK_A14_PLUS = 'network_a14_plus',
}

export type HistoryLog = {
  alters: HistoryLogAlter[]
  category: HistoryLogCategory
  createdAt: string
  resourcableId: string
  resourcableType: 'Campaign' | 'Creation'
  user: Pick<User, 'firstName' | 'lastName'>
  galleryName: string | null
}

export type HistoryLogAlter = {
  before: HistoryLogAlterValue
  after: HistoryLogAlterValue
  attribute: string
}

export type HistoryLogAlterValue = Primitive | Primitive[]

type HistoryLogCategory =
  | 'campaign_created'
  | 'campaign_updated'
  | 'creation_archived'
  | 'creation_created'
  | 'creation_deleted'
  | 'creation_updated'
  | 'creation_acceptance_sent'
  | 'sent_to_brain'
  | 'sent_to_acceptance'

export type CreationAcceptanceDetails = {
  agglomeration: string
  city: string
  creationName: string
  id: string
  rejectionDetails: string
  status: AcceptanceStatus
  creationId: string
  alerts: Alert[]
}

export type CreationAcceptance = {
  acceptances: CreationAcceptanceDetails[]
  building: string
}

export type AcceptanceStatus = 'not_sent' | 'pending_acceptation' | 'accepted' | 'rejected'

export enum EmissionType {
  STANDARD = 'standard',
  SYNCHRONIZED = 'synchronized',
}
