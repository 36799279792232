import React, { ReactElement, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import FlexRow from 'components/Layout/FlexRow'
import FlexCol from 'components/Layout/FlexCol'
import VariableUtils from 'utils/variable'
import ProgressBar from 'components/ProgressBar'
import { Campaign, CampaignBudgetCurrency, CampaignDetails, CampaignTarget } from 'types/campaign'
import service from './service'
import FillingSpinner from 'components/FillingSpinner'
import { CampaignDetailsRealizationProps, ColumnPlacement } from './types'
import budgetImg from 'images/budget.svg'
import emissionsImg from 'images/emissions.svg'
import audienceImg from 'images/audience.svg'
import dollarImg from 'images/dollar.svg'
import CampaignDetailsService from '../services/campaign-details.service'
import './CampaignDetailsRealization.scss'
import { CampaignData } from '../../models/campaign-data.model'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { Money } from '../../../../api/campaign/types'
import { BillingsHelper } from '../../../../utils/billings-helper'
import { AppContext } from '../../../../contexts/AppContext'

const CampaignDetailsRealization: React.FC<CampaignDetailsRealizationProps> = ({
  campaign,
  publicView,
}) => {
  const { t } = useTranslation()
  const { campaignDetails } = useSelector((state: RootState) => state.campaign.details)
  const { id: campaignId, uuid: campaignUuid } = campaign
  const { userData } = useContext(AppContext)

  useEffect(() => {
    service.getData(campaignId, campaignUuid, publicView)
  }, [campaignId, campaignUuid])

  const targetCol = (
    details: CampaignDetails,
    budgetCurrency: CampaignBudgetCurrency
  ): ReactElement => {
    const { budget, emissions, audience } = service.parseTargetValue(
      details.totalBudget,
      details.totalEmissions,
      budgetCurrency,
      details.totalAudience
    )

    const targets = {
      [CampaignTarget.BUDGET]: budget,
      [CampaignTarget.IMPRESSIONS]: emissions,
      [CampaignTarget.AUDIENCE]: audience,
    }

    return (
      <div className='Realization-top__target'>
        {`${t('common.target')}: `}
        <span className='Realization--highlight'>{targets[details.target]}</span>
      </div>
    )
  }

  const estimatedValueCol = (estimatedValue: Money | null): ReactElement => {
    if (!estimatedValue) return <></>

    const { value, currency } = estimatedValue

    return (
      <div className='Realization-top__estimated-value'>
        {`${t('common.estimatedValue')}: `}
        <span className='Realization--highlight'>
          {value} {currency}
        </span>
      </div>
    )
  }

  const termCol = (startDate: string, endDate: string): ReactElement => (
    <div className='Realization-top__term'>
      {`${t('common.campaignTerm')}: `}

      <span className='Realization--highlight'>
        {CampaignDetailsService.campaignTerm(startDate, endDate)}
      </span>
    </div>
  )
  const budgetCol = (
    currentBudget: number,
    budgetCurrency: CampaignBudgetCurrency
  ): ReactElement => (
    <FlexCol className='Realization__budget'>
      <img
        src={budgetImg}
        alt={t('common.budget')}
        className='Realization__budget--img'
      />
      <div>
        <div className='Realization__title'>{t('common.budgetIssued')}:</div>
        <div className='Realization--highlight'>
          {VariableUtils.formatNumber(currentBudget)} {budgetCurrency.toUpperCase()}
        </div>
      </div>
    </FlexCol>
  )

  const emissionsCol = (
    currentEmissions: number,
    columnPlacement: ColumnPlacement
  ): ReactElement => (
    <FlexCol className={'Realization__emissions ' + `Realization__emissions--${columnPlacement}`}>
      <img
        src={emissionsImg}
        alt={t('common.emissions.realized')}
        className='Realization__emissions--img'
      />
      <div>
        <div className='Realization__title'>{t('common.emissions.realized')}:</div>
        <div className='Realization__emissions__value'>
          {VariableUtils.formatNumber(currentEmissions)}
        </div>
      </div>
    </FlexCol>
  )

  const audienceColumn = (
    currentAudience: number,
    columnPlacement: ColumnPlacement
  ): ReactElement => (
    <FlexCol className={'Realization__emissions ' + `Realization__emissions--${columnPlacement}`}>
      <img
        src={audienceImg}
        alt={t('common.emissions.realized')}
        className='Realization__emissions--img'
      />
      <div>
        <div className='Realization__title'>{t('common.audience.realized')}:</div>
        <div className='Realization__emissions__value'>
          {VariableUtils.formatNumber(currentAudience)}
        </div>
      </div>
    </FlexCol>
  )

  const currentValueCol = (
    currentValue: Campaign['currentValue'],
    columnPlacement: ColumnPlacement
  ): ReactElement => {
    if (!currentValue) return <></>

    const { value, currency } = currentValue

    return (
      <FlexCol className={'Realization__emissions ' + `Realization__emissions--${columnPlacement}`}>
        <img
          src={dollarImg}
          alt={t('common.currentValue')}
          className='Realization__current-value__img'
        />
        <div>
          <div className='Realization__title'>{t('common.currentValue')}:</div>
          <div className='Realization__current-value__value'>
            {value} {currency}
          </div>
        </div>
      </FlexCol>
    )
  }

  const progressCol = (details: CampaignDetails): ReactElement => {
    const { targetCompletion, durationDays, remainingDays } = details

    return (
      <FlexCol className='Realization__progress'>
        <div className='Realization__title'>
          {`${t('common.targetRealization')}: `}
          <span className='Realization--highlight'>{targetCompletion}%</span>
        </div>
        <div className='Realization__progress__bar'>
          <ProgressBar percent={targetCompletion > 100 ? 100 : targetCompletion} />
          <div className='Realization__progress__container'>
            <div className='Realization__progress__time'>
              {`${t('campaignDetails.emissionTime')}: ${durationDays} ${t(
                'campaignDetails.realization.days'
              )}`}
            </div>

            <div className='Realization__progress__time'>
              {`${t('common.remaining')}: ${remainingDays} ${t(
                'campaignDetails.realization.days'
              )}`}
            </div>
          </div>
        </div>
      </FlexCol>
    )
  }

  const container = (details: CampaignDetails, data: CampaignData): ReactElement => (
    <div>
      <FlexRow className='Realization-top'>
        {targetCol(details, campaign.budgetCurrency)}
        {BillingsHelper.show(
          data.agencyType,
          userData.user?.roles,
          data.billingAllowance,
          data.createdAt
        ) && estimatedValueCol(data.estimatedValue)}
        {termCol(data.startDate, data.endDate)}
      </FlexRow>
      <FlexRow className='Realization-bottom'>
        {details.target === CampaignTarget.BUDGET && (
          <>
            {budgetCol(details.currentBudget, campaign.budgetCurrency)}
            {progressCol(details)}
            {emissionsCol(details.currentEmissions, ColumnPlacement.RIGHT)}
            {BillingsHelper.show(
              data.agencyType,
              userData.user?.roles,
              data.billingAllowance,
              data.createdAt
            ) && currentValueCol(data.currentValue, ColumnPlacement.RIGHT)}
          </>
        )}

        {details.target === CampaignTarget.IMPRESSIONS && (
          <>
            {emissionsCol(details.currentEmissions, ColumnPlacement.LEFT)}
            {BillingsHelper.show(
              data.agencyType,
              userData.user?.roles,
              data.billingAllowance,
              data.createdAt
            ) && currentValueCol(data.currentValue, ColumnPlacement.LEFT)}
            {progressCol(details)}
          </>
        )}

        {details.target === CampaignTarget.AUDIENCE && (
          <>
            {audienceColumn(details.currentAudience, ColumnPlacement.LEFT)}
            {BillingsHelper.show(
              data.agencyType,
              userData.user?.roles,
              data.billingAllowance,
              data.createdAt
            ) && currentValueCol(data.currentValue, ColumnPlacement.LEFT)}
            {progressCol(details)}
          </>
        )}
      </FlexRow>
    </div>
  )

  return (
    <div className='CampaignDetailsRealization--border-bottom'>
      {campaignDetails ? (
        container(campaignDetails, campaign)
      ) : (
        <FillingSpinner className='CampaignDetailsRealization__loading' />
      )}
    </div>
  )
}

export default CampaignDetailsRealization
