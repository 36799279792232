import { ReactNode } from 'react'
import {
  Campaign,
  CampaignAcceptanceStatus,
  CampaignBudgetCurrency,
  CampaignStatus,
  CampaignTarget,
  Creation,
  CreationFile,
  defaultCommercialAttribute,
  EmissionType,
  MediaFormat,
} from 'types/campaign'
import { OptionalParameters } from 'types/various'
import { Agency } from 'types/agency'
import { SelectAsyncValue } from 'components/Form/SelectAsync'
import { User } from 'types/user'
import { Company } from '../../api/companies/types'

export interface CampaignFormContextProviderProps {
  children: ReactNode
}

export interface CampaignFormContextProps {
  creationsValues: CreationsValues
  setCreationsValues: React.Dispatch<React.SetStateAction<CreationsValues>>
  triggerCreations: boolean
  setTriggerCreations: React.Dispatch<React.SetStateAction<boolean>>
  triggerFetchCampaign: boolean
  setTriggerFetchCampaign: React.Dispatch<React.SetStateAction<boolean>>
}

type BasicsValuesCampaign = Pick<
  Campaign,
  | 'alerts'
  | 'aggregatedAcceptanceAlerts'
  | 'name'
  | 'note'
  | 'contractNote'
  | 'brainState'
  | 'briefName'
  | 'startDate'
  | 'endDate'
  | 'pdfUrl'
  | 'status'
  | 'reservationTill'
  | 'estimatedValue'
  | 'createdAt'
>

export interface BasicsValues extends BasicsValuesCampaign {
  campaignId: Campaign['id']
  agency: SelectAsyncValue<Agency> | null
  advertiser: SelectAsyncValue<Company> | null
  supervisors: SelectAsyncValue<User>[]
}

export interface DetailsValues {
  fetched: boolean
  audience: Campaign['audience']
  hiddenAudience: Campaign['hiddenAudience']
  hiddenAudienceActive: Campaign['hiddenAudienceActive']
  mediumFormat: MediaFormat
  pois: string[]
  target: CampaignTarget
  budget: Campaign['budget']
  budgetCurrency: Campaign['budgetCurrency']
  emissions: Campaign['impressions']
  emissionType: Campaign['emissionType']
  hiddenImpressions: Campaign['hiddenImpressions']
  hiddenImpressionsActive: Campaign['hiddenImpressionsActive']
  acceptanceStatus: CampaignAcceptanceStatus
  priority: Campaign['priority']
  mediaDuration: Campaign['mediaDuration']
  offer: Campaign['offer']
  commercialAttribute: Campaign['commercialAttribute']
  organizationAttribute: Campaign['organizationAttribute']
}

export type CreationsValues = FormCreation[]

export type FormCreation = OptionalParameters<
  Pick<
    Creation,
    | 'id'
    | 'archiveReason'
    | 'mediaFormat'
    | 'thumbnailUrl'
    | 'approved'
    | 'archivedAt'
    | 'state'
    | 'emissionStartDate'
    | 'duration'
    | 'details'
    | 'alerts'
    | 'createdAt'
    | 'schedules'
    | 'impressions'
    | 'containsAlcohol'
    | 'audience'
  > &
    Pick<CreationFile, 'url' | 'isVideo' | 'filename'>,
  'thumbnailUrl' | 'isVideo'
>

export const basicsValuesDefault: BasicsValues = {
  alerts: [],
  aggregatedAcceptanceAlerts: [],
  brainState: {},
  briefName: '',
  campaignId: '',
  agency: null,
  advertiser: null,
  endDate: '',
  name: '',
  note: '',
  contractNote: '',
  pdfUrl: '',
  reservationTill: null,
  startDate: '',
  status: CampaignStatus.SKETCH,
  supervisors: [],
  estimatedValue: {
    valueCents: 0,
    value: '0',
    currency: CampaignBudgetCurrency.PLN,
  },
  createdAt: '',
}

export const detailsValuesDefault: DetailsValues = {
  fetched: false,
  mediumFormat: MediaFormat.DG_CS,
  pois: [],
  target: CampaignTarget.IMPRESSIONS,
  audience: 0,
  hiddenAudience: 0,
  hiddenAudienceActive: false,
  budget: 0,
  budgetCurrency: CampaignBudgetCurrency.PLN,
  emissions: 0,
  emissionType: EmissionType.STANDARD,
  hiddenImpressions: 0,
  hiddenImpressionsActive: false,
  acceptanceStatus: CampaignAcceptanceStatus.not_sent,
  priority: 4,
  offer: null,
  commercialAttribute: defaultCommercialAttribute,
  organizationAttribute: null,
  mediaDuration: 10,
}

export const CampaignFormContextPropsDefault: CampaignFormContextProps = {
  creationsValues: [],
  setCreationsValues: () => void {},
  triggerCreations: false,
  setTriggerCreations: () => void {},
  triggerFetchCampaign: false,
  setTriggerFetchCampaign: () => void {},
}
