import { Dispatch, SetStateAction } from 'react'
import api from 'api'
import { MediaFormatCheckboxes, SystemCapacityPerDay } from './types'
import { MediaFormat } from 'types/campaign'
import EnumUtils from 'utils/enum'
import { InventoryChartFilters } from 'pages/Inventory/types'

export default class InventoryChartService {
  static getData = (
    setSystemCapacity: Dispatch<SetStateAction<SystemCapacityPerDay[]>>,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    filters: InventoryChartFilters
  ): void => {
    setIsLoading(true)

    api.inventory
      .getInventoryForChart({
        ...filters,
        fromDate: filters.fromDate || undefined,
        toDate: filters.toDate || undefined,
      })
      .then(res => {
        const systemCapacity: SystemCapacityPerDay[] = res.data.inventoryForChart.map(system => {
          return {
            date: system.date,
            freeSlots: system.freeSlots,
            plannedSlots: system.plannedSlots,
            reservedSlots: system.reservedSlots,
            p3PlannedSlots: system.p3PlannedSlots,
            p4PlannedSlots: system.p4PlannedSlots,
            p5PlannedSlots: system.p5PlannedSlots,
            p6PlannedSlots: system.p6PlannedSlots,
            p7PlannedSlots: system.p7PlannedSlots,
            total: system.totalSlots,
          }
        })

        void setSystemCapacity(systemCapacity)
      })
      .catch(e => {
        console.error('Failed to fetch inventory data: ', e)
      })
      .finally(() => void setIsLoading(false))
  }

  static mediaFormatsCheckboxesToArray = (mfc: MediaFormatCheckboxes): string[] => {
    const result: string[] = []

    Object.entries(mfc).forEach(([, value]) => {
      Object.entries(value).forEach(([key, value]) => {
        if (value === true) {
          result.push(EnumUtils.getKeyByValueT(MediaFormat, key)!)
        }
      })
    })

    return result
  }
}
