import { gql } from '@apollo/client'
import { MUTATION_STATE } from 'api/fragments'

const PAYLOAD_TYPE = 'UpdateMediaCriteriaSearchPayload'

export const UPDATE_MEDIA_CRITERIA_SEARCH = gql`
    ${MUTATION_STATE(PAYLOAD_TYPE)}
    mutation updateMediaCriteriaSearch(
        $budget: Float
        $campaignId: ID!
        $impressions: Int
        $mediumFormat: MediumFormatEnum!
        $offer: CampaignOfferEnum
        $emissionType: EmissionTypeEnum
        $priority: Int!
        $target: CampaignTargetEnum!
    ) {
        updateMediaCriteriaSearch(
            input: {
                budget: $budget
                campaignId: $campaignId
                impressions: $impressions
                mediumFormat: $mediumFormat
                offer: $offer
                emissionType: $emissionType
                priority: $priority
                target: $target
            }
        ) {
            ...MutationState${PAYLOAD_TYPE}Fields
        }
    }
`

export const UPDATE_MEDIA_CRITERIA_SEARCH_EMPLOYEE = gql`
    ${MUTATION_STATE(PAYLOAD_TYPE)}
    mutation updateMediaCriteriaSearch(
        $budget: Float
        $campaignId: ID!
        $impressions: Int
        $mediumFormat: MediumFormatEnum!
        $offer: CampaignOfferEnum
        $emissionType: EmissionTypeEnum
        $priority: Int!
        $target: CampaignTargetEnum!
        $commercialAttribute: CampaignCommercialAttributeEnum
    ) {
        updateMediaCriteriaSearch(
            input: {
                budget: $budget
                campaignId: $campaignId
                impressions: $impressions
                mediumFormat: $mediumFormat
                offer: $offer
                emissionType: $emissionType
                priority: $priority
                target: $target
                commercialAttribute: $commercialAttribute
            }
        ) {
            ...MutationState${PAYLOAD_TYPE}Fields
        }
    }
`

export const UPDATE_MEDIA_CRITERIA_SEARCH_ADMIN = gql`
    ${MUTATION_STATE(PAYLOAD_TYPE)}
    mutation updateMediaCriteriaSearch(
        $budget: Float
        $campaignId: ID!
        $impressions: Int
        $hiddenImpressions: Int
        $hiddenImpressionsActive: Boolean
        $audience: Int,
        $hiddenAudience: Int,
        $hiddenAudienceActive: Boolean,
        $mediumFormat: MediumFormatEnum!
        $offer: CampaignOfferEnum
        $priority: Int!
        $mediaDuration: Int
        $target: CampaignTargetEnum!
        $commercialAttribute: CampaignCommercialAttributeEnum
    ) {
        updateMediaCriteriaSearch(
            input: {
                budget: $budget
                campaignId: $campaignId
                impressions: $impressions
                hiddenImpressions: $hiddenImpressions
                hiddenImpressionsActive: $hiddenImpressionsActive
                audience: $audience
                hiddenAudience: $hiddenAudience,
                hiddenAudienceActive: $hiddenAudienceActive,
                mediumFormat: $mediumFormat
                offer: $offer
                priority: $priority
                mediaDuration: $mediaDuration
                target: $target
                commercialAttribute: $commercialAttribute
            }
        ) {
            ...MutationState${PAYLOAD_TYPE}Fields
        }
    }
`

export const UPDATE_MEDIA_CRITERIA_SEARCH_SUPPORT = gql`
    ${MUTATION_STATE(PAYLOAD_TYPE)}
    mutation updateMediaCriteriaSearch(
        $budget: Float
        $campaignId: ID!
        $impressions: Int
        $mediumFormat: MediumFormatEnum!
        $offer: CampaignOfferEnum
        $priority: Int!
        $target: CampaignTargetEnum!
    ) {
        updateMediaCriteriaSearch(
            input: {
                budget: $budget
                campaignId: $campaignId
                impressions: $impressions
                mediumFormat: $mediumFormat
                offer: $offer
                priority: $priority
                target: $target
            }
        ) {
            ...MutationState${PAYLOAD_TYPE}Fields
        }
    }
`
