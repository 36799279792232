import { AgencyType } from '../types/agency'
import { Role } from '../types/user'
import { AppMode } from './env'
import DateUtils from './date'

export class BillingsHelper {
  /**
   * Function which should tell if billings should be shown
   *
   * @param agencyType
   * @param roles
   * @param billingAllowance
   * @param createdAt
   */
  static show(
    agencyType?: AgencyType,
    roles?: Role[],
    billingAllowance?: boolean,
    createdAt?: string
  ): boolean {
    /**
     * If campaign is not created yet, we should not show billings
     */
    if (!createdAt) {
      return false
    }

    /**
     * If we are in production, we should not show billings if they are created before 24.04.2024
     */
    if (
      process.env?.REACT_APP_CUSTOM_NODE_ENV === AppMode.PRODUCTION &&
      DateUtils.isBeforeDay(new Date(createdAt), new Date('2024-04-24'))
    ) {
      return false
    }

    /**
     * If we are on any other environment that production, we should not show billings if they are created before 19.08.2024
     */
    if (
      process.env?.REACT_APP_CUSTOM_NODE_ENV !== AppMode.PRODUCTION &&
      DateUtils.isBeforeDay(new Date(createdAt), new Date('2024-04-18'))
    ) {
      return false
    }

    /**
     * Admin can always see billings
     * (billing allowance doesn't matter in this case)
     */
    if (roles && roles.some(role => role.baseRole === 'admin')) {
      return true
    }

    /**
     * Any user can see billings if agency is external and billing allowance is true
     */
    if (agencyType === AgencyType.external && billingAllowance) {
      return true
    }

    /**
     * For other cases like:
     * - no agency (new campaign)
     * - agency is internal
     *
     * we should not show billings
     */
    return false
  }
}
