import React, { useEffect, useState } from 'react'
import Card from 'components/Layout/Card'
import PageHeader from 'components/Layout/PageHeader'
import { useTranslation } from 'react-i18next'
import InventoryChart from './InventoryChart'
import InventoryProgressBar from './InventoryProgressBar'
import useFilters from './useFilters'
import { FilterContext } from 'components/Form/Filters'
import { InventoryContext } from './InventoryContext'
import service from './service'
import { InventoryMediumsAvailabilityInfo } from './types'
import Alerts from '../../components/Alerts'
import { AlertType } from '../../types/campaign'
import StripeCard from '../../components/StripeCard'
import IconAlert from '../../components/Icon/IconAlert'
const Inventory: React.FC = () => {
  const { t } = useTranslation()
  const { availableFilters, filters, setFilters, filtersDebouncedCounter } = useFilters()
  const [inventoryAlerts, setInventoryAlerts] = useState<InventoryMediumsAvailabilityInfo[] | null>(
    null
  )
  useEffect(() => {
    if (!inventoryAlerts) {
      service.getInventoryAlerts(setInventoryAlerts)
    }
  }, [inventoryAlerts])
  return (
    <InventoryContext.Provider value={{ filtersDebouncedCounter }}>
      <FilterContext.Provider value={{ availableFilters, filters, setFilters, disabled: false }}>
        <PageHeader>{t('inventory.title')}</PageHeader>
        {inventoryAlerts && !!inventoryAlerts.length && (
          <StripeCard
            className={'CampaignDetailsAlerts--warn'}
            title={t('common.alert')}
            icon={<IconAlert alertType={AlertType.alert} />}
          >
            <Alerts
              alerts={inventoryAlerts.map(inventoryAlert => {
                return {
                  active: true,
                  message: t('notification.exceeded70PercentMediumsAvailabilityWarning', {
                    cities: inventoryAlert.cities.join(', '),
                    week: inventoryAlert.week,
                  }),
                  type: AlertType.warning,
                }
              })}
            />
          </StripeCard>
        )}

        <Card
          title={t('inventory.systemCapacity')}
          noMarginBody
        >
          <InventoryProgressBar />
          <InventoryChart />
        </Card>
      </FilterContext.Provider>
    </InventoryContext.Provider>
  )
}

export default Inventory
