import { Dispatch, SetStateAction } from 'react'
import api from 'api'
import { InventoryMediumsAvailabilityInfo } from './types'

export default class InventoryService {
  static getInventoryAlerts = (
    setInventoryAlert: Dispatch<SetStateAction<InventoryMediumsAvailabilityInfo[] | null>>
  ): void => {
    api.inventory
      .getInventoryAlerts()
      .then(res => {
        setInventoryAlert(res.data.inventoryAlerts.nodes)
      })
      .catch(() => void {})
  }
}
