import { client } from 'components/ApolloProvider'
import { GET_INVENTORY_FOR_PROGRESS_BAR } from './getInventoryForProgressBar'
import { GET_INVENTORY_FOR_CHART } from './getInventoryForChart'
import {
  GetInventoryForProgressBarAQR,
  GetInventoryForChartAQR,
  GetInventoryForAlertsAQR,
} from './types'
import { InventoryChartFilters } from 'pages/Inventory/types'
import { GET_INVENTORY_ALERTS } from './getInventoryAlerts'

const inventoryApi = {
  getInventoryForProgressBar: (
    variables: Partial<InventoryChartFilters>
  ): Promise<GetInventoryForProgressBarAQR> =>
    client.query({
      query: GET_INVENTORY_FOR_PROGRESS_BAR,
      variables,
    }),

  getInventoryForChart: (
    variables: Partial<InventoryChartFilters>
  ): Promise<GetInventoryForChartAQR> =>
    client.query({
      query: GET_INVENTORY_FOR_CHART,
      variables,
    }),
  getInventoryAlerts: (): Promise<GetInventoryForAlertsAQR> =>
    client.query({
      query: GET_INVENTORY_ALERTS,
    }),
}

export default inventoryApi
