import { gql } from '@apollo/client'
import { CAMPAIGN_MEDIA_CRITERIA_SEARCH_FIELDS } from '../fragments'

export const GET_CAMPAIGN_FOR_DETAILS_FORM = gql`
  ${CAMPAIGN_MEDIA_CRITERIA_SEARCH_FIELDS}
  query campaignForDetailsForm($id: ID!) {
    node(id: $id) {
      ... on Campaign {
        acceptanceStatus
        budget
        budgetCurrency
        id
        impressions
        hiddenImpressions
        emissionType
        hiddenImpressionsActive
        mediaCriteriaSearch {
          ...CampaignMediaCriteriaSearchFields
        }
        offer
        priority
        target
        commercialAttribute
        organizationAttribute {
          social
          commercial
          outsidePh
          complaint
          freeComplaint
          productOffer
        }
        audience
        hiddenAudience
        hiddenAudienceActive
        mediaDuration
      }
    }
  }
`
