import { Primitive } from 'types/various'
import { isArray } from 'lodash'
import DateUtils from 'utils/date'
import { DATE_HOUR_FORMAT } from 'constant'

const formatAlterValue = <V extends Primitive | V[]>(value: V): string => {
  if (isArray(value)) {
    if (isDateRange(value)) return formatDateRange(value)

    return value.map(formatAlterValue).join(', ')
  }

  if (isDate(value)) return formatDate(value)

  return value?.toString() || ''
}

const formatDateRange = (value: string[]): string =>
  `${formatDate(value[0])} → ${formatDate(value[1])}`
const formatDate = (value: string): string => DateUtils.parseAndFormat(value, DATE_HOUR_FORMAT)
const isDateRange = (value: unknown[]): value is string[] =>
  value.length === 2 && isDate(value[0]) && isDate(value[1])
const isDate = (value: unknown): value is string =>
  typeof value === 'string' && DATE_REGEX.test(value)

const DATE_REGEX = /^(\d{1,4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}\.\d{3})\+(\d{2}:\d{2})$/

export default formatAlterValue
