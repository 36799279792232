import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GetInventoryFiltersQueryData, GetInventoryFiltersVariables } from 'api/inventory/types'
import GET_INVENTORY_FILTERS from 'api/inventory/getInventoryFilters'
import { mediaFormatCheckboxes } from './InventoryChart/types'
import service from './InventoryChart/inventory-chart.service'
import { MediaFormat, Medium } from 'types/campaign'
import { useDebounce } from 'utils/tsx'
import { filtersDefault } from 'components/Form/Filters'
import { InventoryAvailableFilters, InventoryChartFilters } from './types'

type Result = {
  availableFilters: InventoryAvailableFilters
  filters: InventoryChartFilters
  setFilters: (filters: InventoryChartFilters) => void
  filtersDebouncedCounter: number
}

const useFilters = (): Result => {
  const [filtersDebouncedCounter, setFiltersDebouncedCounter] = useState<number>(0)

  const [filters, setFilters] = useState<InventoryChartFilters>({
    ...filtersDefault('agglomerations', 'buildings', 'cities', 'mediumAsIds'),
    mediaFormats: service.mediaFormatsCheckboxesToArray(mediaFormatCheckboxes) as MediaFormat[],
    granulation: 'daily',
    slotDuration: 0,
    fromDate: '',
    toDate: '',
  })

  const [availableFilters, setAvailableFilters] = useState<InventoryAvailableFilters>({
    ...filtersDefault('agglomerations', 'buildings', 'cities', 'mediumAsIds'),
  })

  const { data: availableMedia, refetch: refetchFilters } = useQuery<
    GetInventoryFiltersQueryData,
    GetInventoryFiltersVariables
  >(GET_INVENTORY_FILTERS)

  useEffect((): void => {
    if (availableMedia) setAvailableFilters(mediaToFilters(availableMedia.mediaFilter))
  }, [availableMedia])

  const [onFiltersChangeDebounced] = useDebounce((): void => {
    setFiltersDebouncedCounter(prev => prev + 1)
  }, 1000)

  useEffect(() => void refetchFilters(filters), [filtersDebouncedCounter])

  useEffect(onFiltersChangeDebounced, [filters])

  return { availableFilters, filters, setFilters, filtersDebouncedCounter }
}

const mediaToFilters = (media: Medium[]): InventoryAvailableFilters => {
  const mediumAsIds: string[] = []
  const uniqueMediumPropertiesNames: Record<MediumKey, string[]> = {
    agglomeration: [],
    building: [],
    city: [],
  }

  for (const medium of media) {
    for (const [mediumKey, mediumPropertyNames] of Object.entries(uniqueMediumPropertiesNames) as [
      MediumKey,
      string[]
    ][]) {
      const mediumProperty = medium[mediumKey]
      if (mediumProperty && !mediumPropertyNames.includes(mediumProperty.name)) {
        mediumPropertyNames.push(mediumProperty.name)
      }
    }
    mediumAsIds.push(medium.asId.toString())
  }

  return {
    agglomerations: uniqueMediumPropertiesNames.agglomeration,
    buildings: uniqueMediumPropertiesNames.building,
    cities: uniqueMediumPropertiesNames.city,
    mediumAsIds: mediumAsIds,
  }
}

type MediumKey = 'agglomeration' | 'building' | 'city'

export default useFilters
